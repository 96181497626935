import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import BlockContent from '@sanity/block-content-to-react';
// eslint-disable-next-line no-unused-vars
import tw, { styled, css } from 'twin.macro';
import { ImageSlide } from '../entities/image-slide';
import useCompanyInfo from '../hooks/use-company-info';
import PageTemplate from '../components/page-template';
import AddressCard from '../components/address-card';
import SEO from '../components/seo';
import StaticMapFluid from '../components/static-map-fluid';
import HoursBlock from '../components/hours-block';
import ImageSlider from '../components/image-slider';

import {
  FlexContainer,
  PageSection,
  SectionTitle,
  TextContainer,
  TitleBar,
  PageTitle,
} from '../components/ui-elements';

const CategoryDescription = styled.div`
  ${tw`text-base capitalize leading-relaxed`}
  & > ul, & > div > ul {
    ${tw`list-disc list-inside`}
  }
`;

type RetailCategory = {
  _key: string;
  title: string;
  _rawDescription: string;
  brands: string[];
};

type GraphQLData = {
  page: {
    seo: {
      title: string;
      description: string;
      keywords: string[];
    };
    _rawBody: string;
    categories: RetailCategory[];
    images: {
      lightboxDisplay: string;
      slides: ImageSlide[];
    };
  };
  staticMap: {
    childFile: {
      childImageSharp: IGatsbyImageData;
    };
  };
};

type RetailPageProps = PageProps<GraphQLData>;

const RetailStorePage = (props: RetailPageProps) => {
  const { hours, mapMeta } = useCompanyInfo();
  const { data } = props;
  const {
    page: { seo, _rawBody, categories, images },
    staticMap,
  } = data;

  return (
    <PageTemplate>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
      />
      <TitleBar>
        <PageTitle tw="text-primary-100">{seo.title}</PageTitle>
      </TitleBar>
      <PageSection tw="md:mt-8">
        <FlexContainer>
          <TextContainer tw="md:leading-relaxed md:pr-8 md:w-2/3">
            <BlockContent blocks={_rawBody} />
          </TextContainer>
          <ImageSlider
            lightbox={images}
            interval={4000}
            size="1/2"
            tw="pl-12"
          />
        </FlexContainer>
      </PageSection>
      <PageSection tw="w-full bg-primary-300 text-gray-100">
        <FlexContainer tw="">
          <div tw="py-4 md:w-1/3">
            <div tw="mb-8">
              <HoursBlock hours={hours} />
            </div>
            <AddressCard />
          </div>
          <div tw="py-4 md:w-1/3">
            <StaticMapFluid
              fluid={staticMap.childFile.childImageSharp}
              located={mapMeta.mapText}
              mapUrl={mapMeta.mapUrl}
            />
          </div>
        </FlexContainer>
      </PageSection>
      <PageSection>
        <FlexContainer tw="md:flex-wrap md:mt-8">
          {categories.map((cat) => (
            <div tw="w-full pb-4 md:w-1/3 rounded md:p-4" key={cat.title}>
              <div tw="rounded p-4 h-full bg-primary-200 flex flex-col justify-between">
                <div tw="">
                  <SectionTitle>{cat.title}</SectionTitle>
                  <CategoryDescription>
                    <BlockContent blocks={cat._rawDescription} />
                  </CategoryDescription>
                </div>
                <div tw="">
                  <h3 tw="text-base uppercase font-semibold pt-8">Brands:</h3>
                  <div tw="text-lg italic">{cat.brands.join(', ')}</div>
                </div>
              </div>
            </div>
          ))}
        </FlexContainer>
      </PageSection>
    </PageTemplate>
  );
};

export default RetailStorePage;

export const query = graphql`
  query RetailStorePageQuery {
    page: sanityRetailStorePage {
      seo {
        title
        description
        keywords
      }
      _rawBody
      categories {
        _key
        title
        _rawDescription
        brands
      }
      images {
        lightboxDisplay
        slides {
          _key
          alt
          caption
          asset {
            gatsbyImageData(width: 800)
          }
        }
      }
    }
    staticMap(nickname: { eq: "homePage" }) {
      childFile {
        childImageSharp {
          id
          gatsbyImageData(width: 350, layout: CONSTRAINED)
        }
      }
    }
  }
`;
